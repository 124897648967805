import React, { useEffect, useState } from 'react';
import './footer.css';
import EnlacesFooter from '../EnlacesFooter/EnlacesFooter';
import { gobierno, secretarias, atencion, servicios } from '../../json/enlacesFooter';

const Footer = () => {
  const [homes, setHomes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = "https://cmsportal.hidalgo.gob.mx";

  useEffect(() => {
    fetch(`${url}/homes`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setHomes(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        setError(error.toString());
        setIsLoading(false);
      });
  }, []);

  const currentYear = new Date().getFullYear();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const baseFooter = homes.length > 0 ? homes[0].Footer : null;
  const BaseFooterEnlacesAtencion = homes.length > 0 ? homes[0].FooterEnlacesAtencionCiudadana : null;
  const BaseFooterEnlacesGobierno = homes.length > 0 ? homes[0].FooterEnlacesGobierno : null;
  const BaseFooterEnlacesSecretarias = homes.length > 0 ? homes[0].FooterEnlancesSecretarias : null;
  const BaseFooterEnlacesServicios = homes.length > 0 ? homes[0].FooterEnlacesServicios : null;

  if (!baseFooter) {
    return <div>Loading</div>;
  }

  return (
    <div className='container-footer'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-4 col-sm-6 col-12'>
            <img className='img-footer' src='https://cdn.hidalgo.gob.mx/gobierno/images/logos/escudo_blanco.png' alt='Escudo de Hidalgo' />
            <div className='seccion-contacts'>
              <p>{baseFooter.Direccion1} <br />{baseFooter.Direccion2}</p>
              <p>{baseFooter.ContactoText}<br />{baseFooter.ContactoPhone}</p>
              <p>{baseFooter.EmergencyText}<br />{baseFooter.EmergencyPhone}</p>
              <p>{baseFooter.DenunciaText}<br />{baseFooter.DenunciaPhone}</p>
              <p className='footer-sub'><a href={baseFooter.AvisoPrivacidadUrl}>{baseFooter.AvisoPrivacidadText}</a></p>
              <p>{baseFooter.CopyShortText} {currentYear} {baseFooter.CopyLongText}</p>
            </div>
          </div>
          <div className='col-sm-8 col-12'>
            <div className='row'>
              <div className='col-sm-6 col-12'>
                <EnlacesFooter data={BaseFooterEnlacesGobierno ? BaseFooterEnlacesGobierno.FooterData : []} title={BaseFooterEnlacesGobierno.Titulo} />
              </div>
              <div className='col-sm-6 col-12'>
              <EnlacesFooter data={BaseFooterEnlacesAtencion ? BaseFooterEnlacesAtencion.FooterDataAtencionCiudadana : []} title={BaseFooterEnlacesAtencion.Titulo} />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6 col-12'>
                <EnlacesFooter data={BaseFooterEnlacesSecretarias ? BaseFooterEnlacesSecretarias.FooterDataSecretarias : []} title={BaseFooterEnlacesSecretarias.Titulo} />
              </div>
              <div className='col-sm-6 col-12'>
                <EnlacesFooter data={BaseFooterEnlacesServicios ? BaseFooterEnlacesServicios.FooterDataServicios : []} title={BaseFooterEnlacesServicios.Titulo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
