export const gobierno = [
    {
        "mainTitle" : "Gobierno",
        "contenido" : [
            {
                "nombre" : "Gobernador",
                "enlace" : "https://gobierno.hidalgo.gob.mx/gobernador"
            },
            {
                "nombre" : "Gabinete",
                "enlace" : "https://gobierno.hidalgo.gob.mx/gabinete"
            },
            {
                "nombre" : "Dependencias",
                "enlace" : "https://gobierno.hidalgo.gob.mx/dependencias"
            },
            {
                "nombre" : "Federales",
                "enlace" : "https://gobierno.hidalgo.gob.mx/dependenciasfederales"
            },
            {
                "nombre" : "Padrón Único de Beneficiarios",
                "enlace" : ""
            },
            {
                "nombre" : "Ex-Gobernadores",
                "enlace" : ""
            },
            {
                "nombre" : "Informes de Gobierno",
                "enlace" : ""
            },
            {
                "nombre" : "DIF Hidalgo",
                "enlace" : "https://dif.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Transparencia",
                "enlace" : "https://transparencia.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Noticias",
                "enlace" : ""
            },
            {
                "nombre" : "Programas Sociales",
                "enlace" : ""
            }
        ]
    }
];

export const secretarias = [
    {
        "mainTitle" : "Secretarías",
        "contenido" : [
            {
                "nombre" : "Gobierno",
                "enlace" : "https://s-gobierno.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Hacienda",
                "enlace" : "https://hacienda.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Bienestar",
                "enlace" : "https://sebiso.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Infraestructura Pública y Desarrollo Urbano Sostenible",
                "enlace" : "https://s-obraspublicas.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Desarrollo Económico",
                "enlace" : "https://sedeco.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Medio Ambiente y Recursos Naturales",
                "enlace" : "https://s-medioambiente.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Agricultura y Desarrollo Rural",
                "enlace" : "https://sedagro.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Turismo",
                "enlace" : "https://s-turismo.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Contraloría",
                "enlace" : "https://s-contraloria.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Educación Pública",
                "enlace" : "http://sep.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Salud",
                "enlace" : "https://s-salud.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Seguridad Pública",
                "enlace" : "https://s-seguridad.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Trabajo y Previsión Social",
                "enlace" : "https://s-trabajo.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Movilidad y Transporte",
                "enlace" : "https://movilidadytransporte.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Cultura",
                "enlace" : "https://culturahidalgo.gob.mx/"
            }
        ]
    }
];

export const atencion = [
    {
        "mainTitle" : "Atención Ciudadana",
        "contenido" : [
            {
                "nombre" : "Buzón Ciudadano",
                "enlace" : "https://www.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Buzón de quejas",
                "enlace" : ""
            },
            {
                "nombre" : "Teléfonos de emergencia",
                "enlace" : ""
            },
            {
                "nombre" : "Alerta Amber",
                "enlace" : ""
            },
            {
                "nombre" : "Solicitud de Información.",
                "enlace" : ""
            }
        ]
    }
];

export const servicios = [
    {
        "mainTitle" : "Servicios",
        "contenido" : [
            {
                "nombre" : "Trámites y Servicios",
                "enlace" : "https://ruts.hidalgo.gob.mx/"
            },
            {
                "nombre" : "Normateca Estatal",
                "enlace" : "http://intranet.e-hidalgo.gob.mx/"
            },
            {
                "nombre" : "Servicios Internos",
                "enlace" : ""
            },
            {
                "nombre" : "Anuario Estadístico",
                "enlace" : ""
            }
        ]
    }
];
