import React, { useEffect, useState } from 'react';
import './inicio.css';
import { tramites } from '../../json/tramites';
import Convocatorias from '../Convocatorias/Convocatorias';
import IniciativasG from '../IniciativasG/IniciativasG';
import UltimasNoticias from '../UltimasNoticias/UltimasNoticias';
import { carouselhome } from '../../json/carouselInicio';
import ModalConvocatoria from '../Modal/ModalConvocatoria';

const Inicio = () => {
  const isMobile = window.innerWidth <= 576;
  const [homes, setHomes] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const url = "https://cmsportal.hidalgo.gob.mx";

  useEffect(() => {
    fetch(`${url}/homes`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setHomes(data);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);

  const mostrarUltimasNoticias = homes?.length > 0 ? homes[0].UltimasNoticias?.MostrarUltimasNoticias : false;
  const mostrarModal = homes?.length > 0 ? homes[0].modalconvocatoria[0]?.activo : false;
  const mostrarIniciativas = homes?.length > 0 ? homes[0]?.IsVisibleIniciativas : false;

  useEffect(() => {
    if (mostrarModal) {
      const modalConvocatoria = homes[0]?.modalconvocatoria?.[0];

      if (modalConvocatoria) {
        const { activo, fechaInicio, fechaFin } = modalConvocatoria;

        if (fechaInicio === null || fechaFin === null) {
          if (activo) {
            setModalOpen(true);
          }
        } else {
          const inicio = new Date(fechaInicio);
          const fin = new Date(fechaFin);
          const fechaActual = new Date();

          if (fechaActual >= inicio && fechaActual <= fin) {
            setModalOpen(true);
          }
        }
      }
    }
  }, [mostrarModal, homes]);


  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (

    homes && (
      <div>
        <div id="carousel-inicio-gobierno" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            {
              homes[0]?.banner.bannerData.map((item, index) => (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                  <div className="banner1-img" style={{ backgroundImage: `url(${isMobile ? url + item.bannerImgMobile.url : url + item.bannerImg.url})` }}>
                    <div className='backg-wine'></div>

                  </div>
                </div>
              ))
            }
            {/* <img className='logo-h' src='https://cdn.hidalgo.gob.mx/gobierno/images/logos/logo_hgo_h_bd.png' /> */}
            <div className="search">
              <h3>¿Con qué trámite te podemos ayudar?</h3>
              <input
                type="text"
                placeholder="Buscar"
                onKeyPress={({ key, target: { value } }) => {
                  if (key === 'Enter') {
                    window.location.href = "https://ruts.hidalgo.gob.mx/?text=" + value;
                  }
                }}
              />
              <svg className="icon-search" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" viewBox="-8 -3 25 23">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carousel-inicio-gobierno" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carousel-inicio-gobierno" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='title'>{homes.length > 0 ? homes[0]?.tramites.tituloPrincipal : 'Loading...'}</h1>
              <hr className='hr-gob' />
            </div>
          </div>
          <div className='row'>
            {
              homes[0]?.tramites.tramiteData.map((item, index) => (
                <div key={index} className={`tramites-container ${index === 0 || index === 2 || index === 4 ? 'gray-bg' : 'white-bg'}`}>
                  <div className='img-tramites' style={{ backgroundImage: `url(${url}${item.tramiteImg?.url})` }}></div>
                  <p><a href={item.tramiteLink} target='_blank'>{item.tramiteTitulo}</a></p>
                </div>
              ))
            }
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='title'>{homes[0]?.convocatorias.tituloPrincipal}</h1>
              <hr className='hr-gob' />
              <Convocatorias homes={homes} url={url} />
            </div>
          </div>
        </div>
        {
          mostrarIniciativas && (
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <h1 className='title'>{homes[0]?.iniciativasG.iniciativasTitulo}</h1>
                  <hr className='hr-gob' />
                </div>
              </div>
              <div className='row'>
                <IniciativasG homes={homes} url={url} />
              </div>
            </div>
          )
        }
        {mostrarUltimasNoticias && (
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <h1 className='title'>Últimas noticias</h1>
                <hr className='hr-gob' />
                <UltimasNoticias />
              </div>
            </div>
          </div>
        )}
        {/* <button onClick={openModal}>Abrir Modal</button> */}
        {mostrarModal && <ModalConvocatoria isOpen={isModalOpen} onClose={closeModal} data={homes[0]?.modalconvocatoria} />}
      </div>
    )

  )
}

export default Inicio